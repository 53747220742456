import React from 'react';
export default class Resume extends React.Component {
    render() {
        return (
            <React.Fragment>
                {/* <!-- Resume Section
                ================================================== --> */}
                <section id="resume"> 
                    {/* <!-- Education
                    ----------------------------------------------- --> */}
                    <div className="row education">

                        <div className="three columns header-col">
                            <h1><span>Education</span></h1>
                        </div>

                        <div className="nine columns main-col">

                            <div className="row item">

                            <div className="twelve columns">

                                <h3>University of Phayao</h3>
                                <p className="info">Bachelor of Engineering (Computer Engineering)<span>&bull;</span> <em className="date">December 2018</em></p>

                                <p>
                                    GPA: 2.48
                                </p>

                            </div>

                            </div> {/* <!-- item end --> */}

                            <div className="row item">

                            <div className="twelve columns">

                                <h3>Sa School</h3>
                                <p className="info">High School Certificate (Science – Mathematics)<span>&bull;</span> <em className="date">March 2013</em></p>

                                <p>
                                    GPA: 2.81
                                </p>

                            </div>

                            </div> {/* <!-- item end --> */}

                        </div> {/* <!-- main-col end --> */}

                    </div> {/* <!-- End Education --> */}


                    {/* <!-- Work
                    ----------------------------------------------- --> */}
                    <div className="row work">

                        <div className="three columns header-col">
                            <h1><span>Work</span></h1>
                        </div>

                        <div className="nine columns main-col">

                            <div className="row item">

                                <div className="twelve columns">

                                    <h3>Mulberrysoft Company Limited (Trainee)</h3>
                                    <p className="info">Software Developer<span>&bull;</span> <em className="date">23 August 2018 - 23 November 2018</em></p>

                                
                                    <p>
                                    Fix bug application. Create a  video to introduce the application.
                                    Design web application by using marvel app and develop a questionnaire web application by using angular and firebase.
                                    </p>

                                </div>

                            </div> {/* <!-- item end --> */}

                            <div className="row item">

                                <div className="twelve columns">

                                    <h3>I GEAR GEEK Company Limited (Fulltime)</h3>
                                    <p className="info">Full Stack Developer<span>&bull;</span> <em className="date">7 January 2019 - Now</em></p>


                                    <p>
                                    Development process by using agile. implement the payment gateway (KTB, BBL, SCB, 2C2P, truemoney wallet).
                                    Optimize page speed of Nuxt.js Application. Optimize speed of database query.  
                                    </p>

                                </div>

                            </div> {/* <!-- item end --> */}

                        </div> {/* <!-- main-col end --> */}

                    </div> {/* <!-- End Work --> */}


                    {/* <!-- Skills
                    ----------------------------------------------- --> */}
                    <div className="row skill">

                        <div className="three columns header-col">
                            <h1><span>Skills</span></h1>
                        </div>

                        <div className="nine columns main-col">

                            {/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt.
                            </p> */}

                                <div className="bars">

                                <ul className="skills">
                                        {/* <li><span className="bar-expand photoshop"></span><em>Photoshop</em></li>
                                        <li><span className="bar-expand illustrator"></span><em>Illustrator</em></li>
                                        <li><span className="bar-expand wordpress"></span><em>Wordpress</em></li> */}
                                        <li><span className="bar-expand html5"></span><em>HTML5</em></li>
                                        <li><span className="bar-expand css"></span><em>CSS</em></li> 
                                        <li><span className="bar-expand javascript"></span><em>Javascript</em></li>
                                        <li><span className="bar-expand angular"></span><em>Angular</em></li>
                                        <li><span className="bar-expand react"></span><em>React</em></li>
                                        <li><span className="bar-expand firebase"></span><em>Firebase</em></li>
                                        <li><span className="bar-expand git"></span><em>Git</em></li>
                                        <li><span className="bar-expand bootstrap"></span><em>Bootstrap</em></li>
                                        <li><span className="bar-expand mysql"></span><em>MySQL</em></li>
                                        <li><span className="bar-expand laravel"></span><em>Laravel</em></li>
                                        <li><span className="bar-expand codeigniter"></span><em>Codeigniter</em></li>
                                    </ul>

                                </div>{/* <!-- end skill-bars --> */}

                            </div> {/* <!-- main-col end --> */}

                    </div> {/* <!-- End skills --> */}

                </section> {/* <!-- Resume Section End--> */}
            </React.Fragment>
        )
    }
}