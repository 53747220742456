import React from 'react';
import resumeData from '../../resumeData';
export default class About extends React.Component {
    render() {
        return (
            <React.Fragment>
                {/* <!-- About Section
                ================================================== --> */}
                <section id="about">

                    <div className="row">

                        <div className="three columns">

                            <img className="profile-pic"  src={ resumeData.imageProfileUrl } alt="profile-image" />

                        </div>

                        <div className="nine columns main-col">

                            <h2>About Me</h2>

                            <p>{ resumeData.aboutMe }</p>

                            <div className="row">

                            <div className="columns contact-details">

                                <h2>Contact Details</h2>
                                <p className="address">
                                    <span>{ resumeData.name }</span><br/>
                                    <span>{ resumeData.address }</span><br/>
                                    <span>{ resumeData.tel }</span><br/>
                                    <span>{ resumeData.email }</span>
                                </p>

                            </div>

                            <div className="columns download">
                                <p>
                                    <a target="_blank" rel="noopener noreferrer" href="https://onedrive.live.com/pdf?cid=43812B9B35909DD9&authKey=%21AJYfLUjU2argZqc&resid=43812B9B35909DD9%2116188&ithint=%2Epdf&open=1" className="button"><i className="fa fa-download"></i>Download Resume</a>
                                </p>
                            </div>

                            </div> {/* <!-- end row --> */}

                        </div> {/* <!-- end .main-col --> */}

                    </div>

                </section> 
                {/* <!-- About Section End--> */}
            </React.Fragment>
        )
    }
}