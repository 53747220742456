import React from 'react';
export default class Testimonials extends React.Component {
    render() {
        return (
            <React.Fragment>
                {/* <!-- Testimonials Section
                ================================================== --> */}
                <section id="testimonials">

                    <div className="text-container">

                        <div className="row">

                            <div className="two columns header-col">

                            <h1><span>Client Testimonials</span></h1>

                            </div>

                            <div className="ten columns flex-container">

                            <div className="flexslider">

                                <ul className="slides">

                                    <li>
                                        <blockquote>
                                        <p>Sometimes it is the people no one imagines anything of who do the things that no one can imagine.</p>
                                        <cite>Imitation Game (Movie)</cite>
                                        </blockquote>
                                    </li> {/* <!-- slide ends --> */}

                                    <li>
                                        <blockquote>
                                        <p>It's impossible to fail if you learn from your mistakes. Don't give up.</p>
                                        <cite>Steve Jobs</cite>
                                        </blockquote>
                                    </li> {/*  <!-- slide ends --> */}

                                    
                                    <li>
                                        <blockquote>
                                        <p>The design is not just what it looks like, a design is how it works.</p>
                                        <cite>Steve Jobs</cite>
                                        </blockquote>
                                    </li> {/*  <!-- slide ends --> */}

                                </ul>

                            </div> {/* <!-- div.flexslider ends --> */}

                            </div> {/* <!-- div.flex-container ends --> */}

                        </div> {/* <!-- row ends --> */}

                    </div> {/* <!-- text-container ends --> */}

                </section> {/* <!-- Testimonials Section End--> */}
            </React.Fragment>
        )
    }
}