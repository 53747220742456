import React from 'react';
import resumeData from '../../resumeData';
export default class Header extends React.Component {
    render() {
        return (
            <React.Fragment>
            {/* <!-- Header
            ================================================== --> */}
            <header id="home">

               <nav id="nav-wrap">

                  <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                  <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>

                  <ul id="nav" className="nav">
                     <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                     <li><a className="smoothscroll" href="#about">About</a></li>
                     <li><a className="smoothscroll" href="#resume">Resume</a></li>
                     <li><a className="smoothscroll" href="#portfolio">Works</a></li>
                     <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
                     <li><a className="smoothscroll" href="#contact">Contact</a></li>
                  </ul> {/* <!-- end #nav --> */}

               </nav>  {/* <!-- end #nav-wrap --> */}

               <div className="row banner">
                  <div className="banner-text">
                     <h1 className="responsive-headline">I'm { resumeData.name }.</h1>
                     <h3>I'm a <span>{ resumeData.role }</span>. { resumeData.roleDescripttion} . Let's <a className="smoothscroll" href="#about">start scrolling </a>
                     and learn more <a className="smoothscroll" href="#about">about me</a>.</h3>
                     <hr />
                     <ul className="social">
                        <li><a href={ resumeData.facebookUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                        {/* <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li> */}
                        {/* <li><a href={ resumeData.instragramUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li> */}
                        <li><a href={ resumeData.linkedInUrl }><i className="fa fa-linkedin"></i></a></li>
                        <li><a href={ resumeData.githubUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-github"></i></a></li>
                        {/* <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                        <li><a href="#"><i className="fa fa-skype"></i></a></li> */}
                        <li><a href={ resumeData.mediumUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-medium"></i></a></li>
                     </ul>
                  </div>
               </div>

               <p className="scrolldown">
                  <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
               </p>

            </header> {/* <!-- Header End --> */}
            </React.Fragment>
        );
    }
}