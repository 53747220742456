import React from 'react';
export default class Portfolio extends React.Component {
    render() {
        return (
            <React.Fragment>
                {/* <!-- Portfolio Section
                ================================================== --> */}
                <section id="portfolio">

                    <div className="row">

                        <div className="twelve columns collapsed">

                            <h1>Check Out Some of My Works.</h1>

                            {/* <!-- portfolio-wrapper --> */}
                            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">

                            <div className="columns portfolio-item">
                                <div className="item-wrap">

                                    <a href="#modal-02" title="">
                                        <img alt="" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/sampran.PNG?alt=media&token=674fdb3e-b43f-460e-8174-d35cdb17bb62"/>
                                        <div className="overlay">
                                        <div className="portfolio-item-meta">
                                                <h5>Sampran Questionnaire</h5>
                                            <p>Web Development</p>
                                            </div>
                                        </div>
                                        <div className="link-icon"><i className="icon-plus"></i></div>
                                    </a>

                                </div>
                                </div> {/* <!-- item end --> */}

                            <div className="columns portfolio-item">
                                <div className="item-wrap">

                                    <a href="#modal-03" title="">
                                        <img alt="" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/vending-machine%20desktop.png?alt=media&token=a0d8ea04-4a55-4717-94c4-2509db9a43c8"/>
                                        <div className="overlay">
                                        <div className="portfolio-item-meta">
                                                <h5>Vending Maching</h5>
                                            <p>Web Development</p>
                                            </div>
                                        </div>
                                        <div className="link-icon"><i className="icon-plus"></i></div>
                                    </a>

                                </div>
                            </div> {/* <!-- item end --> */}

                            <div className="columns portfolio-item">
                                <div className="item-wrap">

                                    <a href="#modal-04" title="">
                                        <img alt="" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/phoejun.png?alt=media&token=f2021b3f-876e-41ee-8b9e-3c45b5611279"/>
                                        <div className="overlay">
                                        <div className="portfolio-item-meta">
                                                <h5>Calculate </h5>
                                            <p>Web design</p>
                                            </div>
                                        </div>
                                        <div className="link-icon"><i className="icon-plus"></i></div>
                                    </a>

                                </div>
                            </div> {/* <!-- item end --> */}

                            {/* <div className="columns portfolio-item">
                                <div className="item-wrap">

                                    <a href="#modal-04" title="">
                                        <img alt="" with="100" height="100" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/sampran-questionare-design.png?alt=media&token=b774fcee-9a55-4c11-bb37-3723504234e9"/>
                                        <div className="overlay">
                                        <div className="portfolio-item-meta">
                                                <h5>Sampran Questionnaire Web Design</h5>
                                            <p>Web design</p>
                                            </div>
                                        </div>
                                        <div className="link-icon"><i className="icon-plus"></i></div>
                                    </a>

                                </div>
                            </div> <!-- item end --> */}

                            </div> {/* <!-- portfolio-wrapper end --> */}

                        </div> {/* <!-- twelve columns end --> */}


                        {/* <!-- Modal Popup
                        --------------------------------------------------------------- --> */}

                        <div id="modal-02" className="popup-modal mfp-hide">

                            <img className="scale-with-grid" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/sampran.PNG?alt=media&token=674fdb3e-b43f-460e-8174-d35cdb17bb62" alt="" />

                            <div className="description-box">
                                <h4>Sampran Questionnaire</h4>
                                {/* <p><a href="https://sampran-organic-social-mover.firebaseapp.com" target="_blank" rel="noopener noreferrer">https://sampran-organic-social-mover.firebaseapp.com</a></p> */}
                            <span className="categories"><i className="fa fa-tag"></i>Branding, Web Development</span>
                            </div>

                            <div className="link-box">
                            <a href="https://sampran-organic-social-mover.firebaseapp.com" target="_blank" rel="noopener noreferrer">Details</a>
                                <a className="popup-modal-dismiss">Close</a>
                            </div>

                        </div>{/* <!-- modal-02 End --> */}

                        <div id="modal-03" className="popup-modal mfp-hide">

                            <img className="scale-with-grid" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/vending-machine%20desktop.png?alt=media&token=a0d8ea04-4a55-4717-94c4-2509db9a43c8" alt="" />

                            <div className="description-box">
                                <h4>Vending Machine</h4>
                                <p>
                                    {/* <a href="https://marvelapp.com/9fh6cde/screen/47665837" target="_blank" rel="noopener noreferrer">https://marvelapp.com/9fh6cde/screen/47665837</a>                                 */}
                                </p>
                            <span className="categories"><i className="fa fa-tag"></i>Design</span>
                            </div>

                            <div className="link-box">
                            <a href="https://vending-machine.zerojame.dev" target="_blank" rel="noopener noreferrer">Details</a>
                                <a className="popup-modal-dismiss">Close</a>
                            </div>

                        </div>{/* <!-- modal-04 End --> */}

                        <div id="modal-04" className="popup-modal mfp-hide">

                            <img className="scale-with-grid" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/phoejun.png?alt=media&token=f2021b3f-876e-41ee-8b9e-3c45b5611279" alt="" />

                            <div className="description-box">
                                <h4>Calculate Car Tax</h4>
                                <p>
                                    {/* <a href="https://marvelapp.com/9fh6cde/screen/47665837" target="_blank" rel="noopener noreferrer">https://marvelapp.com/9fh6cde/screen/47665837</a>                                 */}
                                </p>
                            <span className="categories"><i className="fa fa-tag"></i>Web Development</span>
                            </div>

                            <div className="link-box">
                            <a href="https://phoejun-inspection.netlify.app/" target="_blank" rel="noopener noreferrer">Details</a>
                                <a className="popup-modal-dismiss">Close</a>
                            </div>

                            </div>{/* <!-- modal-04 End --> */}

                        {/* <div id="modal-04" className="popup-modal mfp-hide">

                        <img className="scale-with-grid" src="https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/sampran-questionare-design.png?alt=media&token=b774fcee-9a55-4c11-bb37-3723504234e9" alt="" />

                        <div className="description-box">
                            <h4>Sampran questionnaire Web Design</h4>
                            <p>
                            </p>
                        <span className="categories"><i className="fa fa-tag"></i>Design</span>
                        </div>

                        <div className="link-box">
                        <a href="https://marvelapp.com/a16d3e4/screen/48916529" target="_blank" rel="noopener noreferrer">Details</a>
                            <a className="popup-modal-dismiss">Close</a>
                        </div>

                        </div><!-- modal-04 End --> */}

                    </div> {/* <!-- row End --> */}

                </section> {/* <!-- Portfolio Section End--> */}
            </React.Fragment>
        )
    }
}