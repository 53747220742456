import React from 'react';
import resumeData from '../../resumeData';
export default class Footer extends React.Component {
    render() {
        return (
            <React.Fragment>
            {/* <!-- footer
            ================================================== --> */}
            <footer>

                <div className="row">

                    <div className="twelve columns">

                        <ul className="social-links">
                        <li><a href={ resumeData.facebookUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                        {/* <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li> */}
                        {/* <li><a href={ resumeData.instragramUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li> */}
                        <li><a href={ resumeData.linkedInUrl }><i className="fa fa-linkedin"></i></a></li>
                        <li><a href={ resumeData.githubUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-github"></i></a></li>
                        <li><a href={ resumeData.mediumUrl } target="_blank" rel="noopener noreferrer"><i className="fa fa-medium"></i></a></li>
                        </ul>

                        <ul className="copyright">
                        <li>&copy; Copyright 2014 CeeVee</li>
                        <li>Design by <a title="Styleshout" href="http://www.styleshout.com/">Styleshout</a></li>   
                        </ul>

                    </div>

                    <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>

                </div>

            </footer>  {/* <!-- Footer End--> */}
            </React.Fragment>
        )
    }
}