let now = new Date();
let nowDate = now.getDate();
let nowMonth = now.getMonth()+1;    
let nowYear = now.getFullYear();

const date = 18;
const month = 11;
const year = 1995;

let age = 0;
//Calculate Age
if(nowYear > year) {
    let ageBeforeDate = nowYear - year - 1;
    let ageBeforeOrEqualDate = nowYear - year;
    if(nowMonth < month) {
        age = ageBeforeDate;
    }else if(nowMonth === month) {
        nowDate >= date ? age = ageBeforeOrEqualDate : age = ageBeforeDate;
    }else if(nowMonth > month) {
        age = ageBeforeOrEqualDate;
    }
}

let resumeData = {
    name: "Thanainan Khamthaen",
    role: "Full stack Developer",
    roleDescripttion: "The role is responsible for designing, coding. The objective is to build a web application with frontend and backend to quickly launch the application",
    facebookUrl: "https://web.facebook.com/thanainan03",
    instragramUrl: "https://www.instagram.com/thanainan03",
    linkedInUrl: "https://www.linkedin.com/in/thanainan-khamthaen",
    githubUrl: "https://github.com/Thainainan03",
    mediumUrl: "https://khamthan02.medium.com",
    imageProfileUrl:"https://firebasestorage.googleapis.com/v0/b/resume-73676.appspot.com/o/profile.jpg?alt=media&token=c67ab77b-a1fb-4876-bf2d-784a4b42f37e",
    aboutMe: "My name is Thanainan Khamthaen. My nickname is Jame. I'm "+age+" years old. My gender is male. I live in Nan. I graduated from University of Phayao, majoring in Computer Engineering. I can use my experience and education to help the company meet and surpass its goals.",
    address: "100 Village No.8, Lainan Sub-district, Wiangsa District, Nan, 55110.",
    tel: "(+66)80 671 8457",
    "email": "thanainan_khamthaen@outlook.com",
    education: [
        {
            "institution": "University of Phayao"
        },
        {
            "institution": "Sa school"
        }
    ],
    age: age
}
  export default resumeData;